<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-text-field v-model="survey.name" label="Survey Title"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field v-model="survey.description" label="Description"></v-text-field>
      </v-col>
    </v-row>
    <v-btn color="accent" @click="sendData">
      <v-icon left>mdi-content-save</v-icon>Save
    </v-btn>
  </v-container>
</template>
<script>
export default {
  props: {
    itemsData: {
      type: Object
    }
  },
  data() {
    return {
      survey: {}
    };
  },
  watch: {
    itemsData: {
      handler: "setSurvey",
      immediate: true
    }
  },
  methods: {
    setSurvey(val) {
      this.survey = { ...val };
    },
    sendData() {
      this.$emit("data", this.survey);
    }
  }
};
</script>